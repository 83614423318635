<template>
  <div>
    <div class="air__utils__heading">
      <h5>Control de Personal</h5>
    </div>
    <my-breadcrumbs :routes="routes"/>
    <!--    Enlaces-->
    <div class="row">

      <div class="col-lg-4 col-md-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: 'assistsControl'}">
                <b-iconstack font-scale="3" variant="primary">
                  <b-icon stacked icon="key" rotate="180" scale="0.75" shift-v="-5" shift-h="10"/>
                  <b-icon stacked icon="person" scale="1.3"/>
                </b-iconstack>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: 'assistsControl'}">
              <h5 class="text-block text-secondary">Control de Asistencias</h5>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: 'adminPersonal'}">
                <b-iconstack font-scale="3" variant="primary">
                  <b-icon  stacked icon="person" scale="0.75" shift-h="-6" shift-v="-1"/>
                  <b-icon stacked icon="person-fill"/>
                  <b-icon  stacked icon="person" scale="0.75" shift-h="6" shift-v="-1"/>
                </b-iconstack>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: 'adminPersonal'}">
              <h5 class="text-block text-secondary">Administración de Personal</h5>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: 'assistsReport'}">
                <b-iconstack font-scale="3" variant="primary">
                  <b-icon stacked icon="person-square" scale="0.5" shift-v="4"/>
                  <b-icon stacked icon="journal" scale="1.3"/>
                  <b-icon stacked icon="card-checklist" scale="0.5" shift-v="-4"/>
                </b-iconstack>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: 'assistsReport'}">
              <h5 class="text-block text-secondary">Reporte de Asistencia</h5>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!--    Enlaces-->

  </div>
</template>

<script>

export default {
  name: 'personalMenu',
  components: {

  },
  data() {
    return {
      // Contants
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Operaciones',
          name: 'operations',
        },
        {
          breadcrumbName: 'Control de Operaciones',
          name: 'operationControl',
        },
        {
          breadcrumbName: 'Control de Personal',
        },
      ],
    }
  },
  methods: {

  },
}
</script>

<style scoped>
.border-bottom {
  border-width:3px !important;
}
</style>
